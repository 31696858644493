import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { fetcher } from "../utils/fetcher";
import { useQuery } from "../utils/useQuery";

const StyledRegister = styled.div``;

const Register = ({ login, globalState, setGlobalState, notify }) => {
  const navigate = useNavigate();
  const urlToken = useQuery().get("t");

  //States
  const [formData, setFormData] = useState({
    repeatPassword: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);

  //Functions
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.repeatPassword) {
      notify.error("Lösenorden stämmer inte överens");
      return;
    }
    setLoading(true);
    const { err } = await fetcher({
      path: "users-no-auth/create-password",
      method: "POST",
      body: formData.password,
      auth: urlToken,
    });
    if (err) {
      notify.error("Lösenordet uppfyller inte kraven");
      return;
    }
    login(
      formData,
      (data) => {
        notify.success(`Välkommen ${data.user.firstName}!`);
      },
      (error) => {
        setLoading(false);
        notify.error(error);
      }
    );
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const verifyToken = async (token) => {
    const { err, data } = await fetcher({
      path: "admin-no-auth/verify-register-token",
      auth: token,
    });
    if (err) return false;
    else return data;
  };

  const checkUser = async () => {
    const storedToken = localStorage.getItem("bg-kurswebb-admin-token");
    if (globalState.user) navigate("/", { replace: true });
    else if (storedToken) {
      setGlobalState({
        ...globalState,
        appLoading: true,
      });

      const user = await verifyToken(storedToken);
      if (!user) {
        localStorage.removeItem("bg-kurswebb-admin-token");
        setGlobalState({
          ...globalState,
          appLoading: false,
        });
        return;
      }
      setGlobalState({
        ...globalState,
        user,
        token: storedToken,
      });
    } else localStorage.removeItem("bg-kurswebb-admin-token");
  };

  const confirmUrlToken = async () => {
    const { err } = await fetcher({
      path: "admin-no-auth/verify-register-token",
      auth: urlToken,
    });
    if (err) {
      setVerified(false);
      setLoading(false);
    } else {
      setVerified(true);
      setLoading(false);
    }
  };

  //Effects
  useEffect(() => {
    checkUser();
    //eslint-disable-next-line
  }, [globalState.user]);

  useEffect(() => {
    confirmUrlToken();
    //eslint-disable-next-line
  }, [urlToken]);

  if (loading)
    return (
      <StyledRegister>
        <p>Verifierar länk</p>
      </StyledRegister>
    );
  else if (!loading && !verified)
    return (
      <StyledRegister>
        <p>Felaktig länk</p>
      </StyledRegister>
    );
  else if (!loading && verified)
    return (
      <StyledRegister>
        <h1>Användareregistrering</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            value={formData.repeatPassword}
            name="repeatPassword"
            disabled={loading}
            onChange={handleChange}
            placeholder="Upprepa lösenord"
          />
          <input
            type="password"
            value={formData.password}
            name="password"
            disabled={loading}
            onChange={handleChange}
            placeholder="Lösenord"
          />
          <button type="submit" disabled={loading}>
            {loading ? "Skapar konto..." : "Skapa konto"}
          </button>
        </form>
      </StyledRegister>
    );
  else return null;
};

export default Register;
