import styled from "styled-components";

const StyledSVG = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: ${({ fill }) => fill};
    transition: 100ms ease-in-out;
    path,
    circle {
      stroke: ${({ stroke }) => stroke};
      fill: ${({ fill }) => fill};
      transition: 100ms ease-in-out;
    }
  }
`;

const icons = {
  "bookmark-simple": (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 14L8 11.5L4 14V3C4 2.86739 4.05268 2.74021 4.14645 2.64645C4.24021 2.55268 4.36739 2.5 4.5 2.5H11.5C11.6326 2.5 11.7598 2.55268 11.8536 2.64645C11.9473 2.74021 12 2.86739 12 3V14Z"
        stroke="#555552"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  books: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 2.5H3C2.72386 2.5 2.5 2.72386 2.5 3V13C2.5 13.2761 2.72386 13.5 3 13.5H5C5.27614 13.5 5.5 13.2761 5.5 13V3C5.5 2.72386 5.27614 2.5 5 2.5Z"
        stroke="#2B2B2B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 5H5.5"
        stroke="#424242"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 2.5H6C5.72386 2.5 5.5 2.72386 5.5 3V13C5.5 13.2761 5.72386 13.5 6 13.5H8C8.27614 13.5 8.5 13.2761 8.5 13V3C8.5 2.72386 8.27614 2.5 8 2.5Z"
        stroke="#424242"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 11H8.5"
        stroke="#424242"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9709 2.33639L9.0309 2.85639C8.76304 2.92819 8.6041 3.20353 8.6759 3.47139L11.2759 13.1714C11.3477 13.4392 11.623 13.5982 11.8909 13.5264L13.8309 13.0064C14.0988 12.9346 14.2577 12.6592 14.1859 12.3914L11.5859 2.69139C11.5141 2.42353 11.2388 2.26459 10.9709 2.33639Z"
        stroke="#424242"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7002 11.2L13.6002 10.4188"
        stroke="#424242"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.1499 5.4L12.0499 4.625"
        stroke="#424242"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  brain: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 11V5"
        stroke="#424242"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 9C5.99445 9 6.4778 9.14662 6.88893 9.42133C7.30005 9.69603 7.62048 10.0865 7.8097 10.5433C7.99892 11.0001 8.04843 11.5028 7.95196 11.9877C7.8555 12.4727 7.6174 12.9181 7.26777 13.2678C6.91814 13.6174 6.47268 13.8555 5.98773 13.952C5.50277 14.0484 5.00011 13.9989 4.54329 13.8097C4.08648 13.6205 3.69603 13.3 3.42133 12.8889C3.14662 12.4778 3 11.9945 3 11.5V11.0813"
        stroke="#424242"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 9C10.0055 9 9.5222 9.14662 9.11108 9.42133C8.69995 9.69603 8.37952 10.0865 8.1903 10.5433C8.00108 11.0001 7.95157 11.5028 8.04804 11.9877C8.1445 12.4727 8.3826 12.9181 8.73223 13.2678C9.08187 13.6174 9.52732 13.8555 10.0123 13.952C10.4972 14.0484 10.9999 13.9989 11.4567 13.8097C11.9135 13.6205 12.304 13.3 12.5787 12.8889C12.8534 12.4778 13 11.9945 13 11.5V11.0813"
        stroke="#424242"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.50025 11.25H4.00025C3.29113 11.2519 2.60427 11.0026 2.06152 10.5462C1.51877 10.0898 1.15521 9.45599 1.03536 8.75708C0.915501 8.05817 1.04709 7.3394 1.40679 6.72828C1.76648 6.11716 2.33102 5.65322 3.00025 5.41875V4.5C3.00025 3.83696 3.26364 3.20107 3.73248 2.73223C4.20132 2.26339 4.8372 2 5.50025 2C6.16329 2 6.79917 2.26339 7.26801 2.73223C7.73685 3.20107 8.00025 3.83696 8.00025 4.5V11.5"
        stroke="#424242"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 11.25H12C12.7091 11.2519 13.396 11.0026 13.9387 10.5462C14.4815 10.0898 14.845 9.45599 14.9649 8.75708C15.0847 8.05817 14.9532 7.3394 14.5935 6.72828C14.2338 6.11716 13.6692 5.65322 13 5.41875V4.5C13 3.83696 12.7366 3.20107 12.2678 2.73223C11.7989 2.26339 11.163 2 10.5 2C9.83696 2 9.20107 2.26339 8.73223 2.73223C8.26339 3.20107 8 3.83696 8 4.5"
        stroke="#424242"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 5.25V5.75C5.5 6.21413 5.31563 6.65925 4.98744 6.98744C4.65925 7.31563 4.21413 7.5 3.75 7.5"
        stroke="#424242"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 5.25V5.75C10.5 6.21413 10.6844 6.65925 11.0126 6.98744C11.3408 7.31563 11.7859 7.5 12.25 7.5"
        stroke="#424242"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),

  "chevron-down": (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 6L8 11L3 6"
        stroke="#757575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  "chevron-left": (
    <svg
      width="16"
      height="16"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 26L10 16L20 6"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  "chevron-right": (
    <svg
      width="16"
      height="16"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6L22 16L12 26"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  "chevron-up": (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 10L8 6L4 10"
        stroke="#F5F5F5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  "eye-closed": (
    <svg
      width="16"
      height="16"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.1375 15.9125L28 20.85"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2749 18.6625L20.1624 23.7"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7125 18.65L11.825 23.7"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.85005 15.9125L3.98755 20.875"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 13.1125C6.1 15.7125 9.95 19 16 19C22.05 19 25.9 15.7125 28 13.1125"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  eye: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 3.5C3 3.5 1 8 1 8C1 8 3 12.5 8 12.5C13 12.5 15 8 15 8C15 8 13 3.5 8 3.5Z"
        stroke="#757575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10.5C9.38071 10.5 10.5 9.38071 10.5 8C10.5 6.61929 9.38071 5.5 8 5.5C6.61929 5.5 5.5 6.61929 5.5 8C5.5 9.38071 6.61929 10.5 8 10.5Z"
        stroke="#757575"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  lightning: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 22.5L10.5 15L4.5 12.75L15 1.5L13.5 9L19.5 11.25L9 22.5Z"
        stroke="#F7E214"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  play: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2563 7.57506L5.2625 2.07506C5.18679 2.02836 5.09999 2.0027 5.01106 2.00073C4.92212 1.99877 4.83427 2.02056 4.75657 2.06387C4.67887 2.10718 4.61413 2.17043 4.56903 2.24711C4.52393 2.32378 4.5001 2.4111 4.5 2.50006V13.5001C4.5001 13.589 4.52393 13.6763 4.56903 13.753C4.61413 13.8297 4.67887 13.8929 4.75657 13.9362C4.83427 13.9796 4.92212 14.0014 5.01106 13.9994C5.09999 13.9974 5.18679 13.9718 5.2625 13.9251L14.2563 8.42506C14.3301 8.38139 14.3913 8.31923 14.4338 8.2447C14.4763 8.17017 14.4987 8.08586 14.4987 8.00006C14.4987 7.91426 14.4763 7.82995 14.4338 7.75542C14.3913 7.68089 14.3301 7.61873 14.2563 7.57506V7.57506Z"
        stroke="#0A0A0A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  user: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 10C10.2091 10 12 8.20914 12 6C12 3.79086 10.2091 2 8 2C5.79086 2 4 3.79086 4 6C4 8.20914 5.79086 10 8 10Z"
        stroke="#2B2B2B"
        strokeMiterlimit="10"
      />
      <path
        d="M1.9375 13.5C2.55184 12.4357 3.43552 11.5519 4.49972 10.9374C5.56392 10.323 6.77113 9.99945 8 9.99945C9.22887 9.99945 10.4361 10.323 11.5003 10.9374C12.5645 11.5519 13.4482 12.4357 14.0625 13.5"
        stroke="#424242"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  "log-out": (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 17L21 12L16 7"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 12H9"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  "align-right": (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 10H7"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 6H3"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 14H3"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 18H7"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),

  x: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18"
        stroke="#0A0A0A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke="#0A0A0A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  bookmark: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 12L5 9.5L1 12V1C1 0.867392 1.05268 0.740215 1.14645 0.646447C1.24021 0.552679 1.36739 0.5 1.5 0.5H8.5C8.63261 0.5 8.75979 0.552679 8.85355 0.646447C8.94732 0.740215 9 0.867392 9 1V12Z"
        stroke="#555552"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  hamburger: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 8H14"
        stroke="#F5F5F5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 4H14"
        stroke="#F5F5F5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 12H14"
        stroke="#F5F5F5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  save: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H10.6667L14 5.33333V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14Z"
        stroke="#0A0A0A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3337 14V8.66666H4.66699V14"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66699 2V5.33333H10.0003"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  "upload-cloud": (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 8V14"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5933 12.26C14.2435 11.9055 14.7572 11.3446 15.0532 10.6658C15.3493 9.98692 15.4108 9.22883 15.2281 8.51114C15.0454 7.79344 14.629 7.15702 14.0444 6.70231C13.4599 6.2476 12.7406 6.0005 12 6.00001H11.16C10.9582 5.2195 10.5821 4.4949 10.0599 3.88067C9.5378 3.26644 8.8832 2.77858 8.14537 2.45375C7.40754 2.12892 6.60567 1.97558 5.80005 2.00526C4.99443 2.03495 4.20602 2.24688 3.49409 2.62512C2.78216 3.00336 2.16525 3.53808 1.68972 4.18906C1.2142 4.84005 0.892434 5.59037 0.748627 6.38361C0.60482 7.17684 0.64271 7.99236 0.859449 8.76885C1.07619 9.54534 1.46613 10.2626 1.99997 10.8667"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6663 10.6667L7.99967 8L5.33301 10.6667"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  cardholder: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 3.5H3C2.44772 3.5 2 3.94772 2 4.5V11.5C2 12.0523 2.44772 12.5 3 12.5H13C13.5523 12.5 14 12.0523 14 11.5V4.5C14 3.94772 13.5523 3.5 13 3.5Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 7.50001H5.55C5.66515 7.49937 5.7769 7.539 5.86592 7.61205C5.95495 7.68509 6.01564 7.78695 6.0375 7.90001C6.13298 8.34971 6.38001 8.75297 6.73726 9.04233C7.0945 9.33168 7.54028 9.48957 8 9.48957C8.45972 9.48957 8.9055 9.33168 9.26274 9.04233C9.61999 8.75297 9.86702 8.34971 9.9625 7.90001C9.98436 7.78695 10.0451 7.68509 10.1341 7.61205C10.2231 7.539 10.3348 7.49937 10.45 7.50001H14"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 5.5H14"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  "plus-circle": (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99967 14.6666C11.6816 14.6666 14.6663 11.6819 14.6663 7.99998C14.6663 4.31808 11.6816 1.33331 7.99967 1.33331C4.31778 1.33331 1.33301 4.31808 1.33301 7.99998C1.33301 11.6819 4.31778 14.6666 7.99967 14.6666Z"
        stroke="#0A0A0A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 5.33331V10.6666"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33301 8H10.6663"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  plus: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 3.33334V12.6667"
        stroke="#555552"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33301 8H12.6663"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  minus: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33301 8H12.6663"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  image: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 2H3.33333C2.59695 2 2 2.59695 2 3.33333V12.6667C2 13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V3.33333C14 2.59695 13.403 2 12.6667 2Z"
        stroke="#555552"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.66699 6.66663C6.21928 6.66663 6.66699 6.21891 6.66699 5.66663C6.66699 5.11434 6.21928 4.66663 5.66699 4.66663C5.11471 4.66663 4.66699 5.11434 4.66699 5.66663C4.66699 6.21891 5.11471 6.66663 5.66699 6.66663Z"
        stroke="#555552"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9997 9.99996L10.6663 6.66663L3.33301 14"
        stroke="#555552"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  video: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3337 4.66663L10.667 7.99996L15.3337 11.3333V4.66663Z"
        stroke="#555552"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33366 3.33337H2.00033C1.26395 3.33337 0.666992 3.93033 0.666992 4.66671V11.3334C0.666992 12.0698 1.26395 12.6667 2.00033 12.6667H9.33366C10.07 12.6667 10.667 12.0698 10.667 11.3334V4.66671C10.667 3.93033 10.07 3.33337 9.33366 3.33337Z"
        stroke="#555552"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  link: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66699 8.66672C6.95329 9.04948 7.31856 9.36618 7.73803 9.59535C8.15749 9.82452 8.62133 9.9608 9.09809 9.99495C9.57485 10.0291 10.0534 9.9603 10.5012 9.79325C10.9491 9.62619 11.3557 9.36477 11.6937 9.02672L13.6937 7.02672C14.3009 6.39805 14.6368 5.55604 14.6292 4.68205C14.6216 3.80806 14.2711 2.97202 13.6531 2.354C13.035 1.73597 12.199 1.38541 11.325 1.37781C10.451 1.37022 9.609 1.7062 8.98033 2.31339L7.83366 3.45339"
        stroke="#555552"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33347 7.33332C9.04716 6.95057 8.68189 6.63387 8.26243 6.40469C7.84297 6.17552 7.37913 6.03924 6.90237 6.0051C6.4256 5.97095 5.94707 6.03974 5.49924 6.2068C5.0514 6.37386 4.64472 6.63527 4.3068 6.97332L2.3068 8.97332C1.69961 9.60199 1.36363 10.444 1.37122 11.318C1.37881 12.192 1.72938 13.028 2.3474 13.646C2.96543 14.2641 3.80147 14.6146 4.67546 14.6222C5.54945 14.6298 6.39146 14.2938 7.02013 13.6867L8.16013 12.5467"
        stroke="#555552"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  richtext: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 4.25H13.5"
        stroke="#555552"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 6.75H10.5"
        stroke="#555552"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 9.25H13.5"
        stroke="#555552"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 11.75H10.5"
        stroke="#555552"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  text: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 3.5V12.5"
        stroke="#555552"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 5.5V3.5H13V5.5"
        stroke="#555552"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 12.5H10"
        stroke="#555552"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  edit: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 2L14 5.33333L5.33333 14H2V10.6667L10.6667 2Z"
        stroke="#2B2B2B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  trash: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 4H3.33333H14"
        stroke="#0A0A0A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.3335 4.00001V2.66668C5.3335 2.31305 5.47397 1.97392 5.72402 1.72387C5.97407 1.47382 6.31321 1.33334 6.66683 1.33334H9.3335C9.68712 1.33334 10.0263 1.47382 10.2763 1.72387C10.5264 1.97392 10.6668 2.31305 10.6668 2.66668V4.00001M12.6668 4.00001V13.3333C12.6668 13.687 12.5264 14.0261 12.2763 14.2762C12.0263 14.5262 11.6871 14.6667 11.3335 14.6667H4.66683C4.31321 14.6667 3.97407 14.5262 3.72402 14.2762C3.47397 14.0261 3.3335 13.687 3.3335 13.3333V4.00001H12.6668Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.6665 7.33334V11.3333"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.3335 7.33334V11.3333"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  "arrow-up-square": (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 5H6C5.44772 5 5 5.44772 5 6V26C5 26.5523 5.44772 27 6 27H26C26.5523 27 27 26.5523 27 26V6C27 5.44772 26.5523 5 26 5Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7625 15.2375L16 11L20.2375 15.2375"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 21V11"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  "arrow-down-square": (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 5H6C5.44772 5 5 5.44772 5 6V26C5 26.5523 5.44772 27 6 27H26C26.5523 27 27 26.5523 27 26V6C27 5.44772 26.5523 5 26 5Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7625 16.7625L16 21L20.2375 16.7625"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 11V21"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  flag: (
    <svg
      width="10"
      height="13"
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 12L5 9.5L1 12V1C1 0.867392 1.05268 0.740215 1.14645 0.646447C1.24021 0.552679 1.36739 0.5 1.5 0.5H8.5C8.63261 0.5 8.75979 0.552679 8.85355 0.646447C8.94732 0.740215 9 0.867392 9 1V12Z"
        stroke="#555552"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  check: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3337 4L6.00033 11.3333L2.66699 8"
        stroke="#555552"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  pokal: (
    <svg
      width="56"
      height="54"
      viewBox="0 0 56 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.668 13.8858C41.668 13.8858 46.4159 6.39488 51.5041 10.8153C56.6285 15.2655 52.3511 22.9468 48.5873 26.4341"
        stroke="black"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M14.953 13.8858C14.953 13.8858 10.2051 6.39488 5.11683 10.8153C-0.0091443 15.2655 4.26826 22.9468 8.03206 26.4341"
        stroke="black"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M15.0059 2H41.7214V23.806C41.7214 27.3487 40.3141 30.7463 37.8091 33.2514C35.3041 35.7565 31.9066 37.1639 28.3639 37.164V37.164C24.8211 37.164 21.4235 35.7567 18.9183 33.2516C16.4132 30.7464 15.0059 27.3488 15.0059 23.806V2Z"
        stroke="black"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M28.3633 37.1641V49.5927"
        stroke="black"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M18.0859 51.67H38.6403"
        stroke="black"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
    </svg>
  ),
  retry: (
    <svg
      width="51"
      height="48"
      viewBox="0 0 51 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.998 17.0211V2H50.7632"
        stroke="black"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M34.0509 2C38.8599 3.99544 42.8464 7.54216 45.3629 12.0641C47.8793 16.586 48.7779 21.8175 47.9126 26.9089C47.0474 32.0003 44.4691 36.6525 40.5966 40.11C36.7242 43.5674 31.7849 45.6269 26.5812 45.954C21.3774 46.2811 16.2148 44.8566 11.9318 41.9118C7.64886 38.9671 4.49716 34.6751 2.9886 29.7329C1.48005 24.7907 1.70326 19.4887 3.62198 14.6879C5.5407 9.88721 9.04222 5.8698 13.5578 3.2882"
        stroke="black"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
    </svg>
  ),
  "refresh-ccw": (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.666016 2.66663V6.66663H4.66602"
        stroke="#2B2B2B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.334 13.3334V9.33337H11.334"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6593 6.00001C13.3212 5.04453 12.7466 4.19028 11.989 3.51696C11.2315 2.84363 10.3157 2.37319 9.32716 2.14952C8.33861 1.92584 7.30951 1.95624 6.33589 2.23786C5.36226 2.51948 4.47585 3.04315 3.75935 3.76001L0.666016 6.66668M15.3327 9.33334L12.2393 12.24C11.5229 12.9569 10.6364 13.4805 9.66281 13.7622C8.68918 14.0438 7.66009 14.0742 6.67154 13.8505C5.68299 13.6268 4.76722 13.1564 4.00966 12.4831C3.2521 11.8097 2.67746 10.9555 2.33935 10"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  pdf: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 8V2.5C3 2.36739 3.05268 2.24021 3.14645 2.14645C3.24021 2.05268 3.36739 2 3.5 2H9.5L13 5.5V8"
        stroke="#101828"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 2V5.5H13"
        stroke="#101828"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 12.5H4C4.26522 12.5 4.51957 12.3946 4.70711 12.2071C4.89464 12.0196 5 11.7652 5 11.5C5 11.2348 4.89464 10.9804 4.70711 10.7929C4.51957 10.6054 4.26522 10.5 4 10.5H3V13.5"
        stroke="#101828"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 10.5H11.75V13.5"
        stroke="#101828"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.25 12.25H11.75"
        stroke="#101828"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 13.5C8.39782 13.5 8.77936 13.342 9.06066 13.0607C9.34196 12.7794 9.5 12.3978 9.5 12C9.5 11.6022 9.34196 11.2206 9.06066 10.9393C8.77936 10.658 8.39782 10.5 8 10.5H7.125V13.5H8Z"
        stroke="#101828"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  "bg-logo": (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.51143 18V15.9433C8.65131 15.9433 9.72985 15.5134 10.471 14.7628C11.1125 14.1136 11.4461 13.263 11.4384 12.3032C11.4333 11.7207 11.3336 11.1972 11.1662 10.7328C9.90748 11.8182 8.39062 12.3635 7.30313 12.3635C5.01953 12.3635 3.79275 10.6545 3.79275 9.00193C3.79275 7.34935 5.01825 5.64032 7.30313 5.64032C8.39062 5.64032 9.90748 6.18433 11.1662 7.27108C11.3336 6.80661 11.4333 6.28313 11.4384 5.70062C11.4473 4.75372 11.1113 3.90819 10.4672 3.2564C9.7273 2.50709 8.62192 2.05931 7.51143 2.05931H0.513672V0H7.51143C9.18164 0 10.7892 0.658208 11.9214 1.80654C12.9578 2.85737 13.4996 4.20971 13.4856 5.7173C13.4741 6.99651 13.1367 8.09095 12.609 9.00064C13.1367 9.91033 13.4741 11.0048 13.4856 12.284C13.4996 13.8044 12.9591 15.1619 11.9253 16.2101C10.802 17.3482 9.19314 18 7.51143 18ZM7.30313 7.69577C6.2642 7.69577 5.8425 8.3681 5.8425 9.00064C5.8425 9.63319 6.2642 10.3055 7.30313 10.3055C7.93952 10.3055 9.10369 9.8975 10.0353 9.00064C9.10369 8.10379 7.93952 7.69577 7.30313 7.69577Z"
        fill="#F7E214"
      />
    </svg>
  ),
  "music-notes": (
    <svg
      fill="none"
      height="32"
      width="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M22.5 25a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-16 4a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM26 10l-16 4"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10 25.5V8l16-4v17.5"
      />
    </svg>
  ),
};

const SVG = ({ name, fill, stroke }) => {
  const icon = icons[name];
  if (!icon) return null;
  return (
    <StyledSVG fill={fill} stroke={stroke}>
      {icon}
    </StyledSVG>
  );
};

export default SVG;
