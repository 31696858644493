import React from "react";
import styled from "styled-components";

const StyledPlus = styled.div`
  height: 15px;
  width: 15px;
  position: relative;
  ${(props) => (props.cross ? "" : "transform: rotateZ(45deg);")}
  transition: 200ms ease-in-out;
  div {
    transition: 200ms ease-in-out;
    position: absolute;
    width: 1.5px;
    height: 15px;
    background: ${(props) => (props.color ? props.color : "#000")};

    &:nth-child(1) {
      /* top: calc(50% - 1px); */
      left: calc(50% - 1px);
      transform: rotateZ(45deg);
    }
    &:nth-child(2) {
      /* top: calc(50% - 1px); */
      left: calc(50% - 1px);
      transform: ${(props) =>
        props.minus ? "rotateZ(45deg)" : "rotateZ(-45deg)"};
      opacity: ${(props) => (props.minus ? 0 : 1)};
    }
  }
`;

const Plus = (props) => {
  return (
    <StyledPlus
      cross={props.cross}
      color={props.color}
      minus={props.minus}
      className={props.className}
    >
      <div></div>
      <div></div>
    </StyledPlus>
  );
};

export default Plus;
