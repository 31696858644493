import styled from "styled-components";
import { colors, measurments } from "../utils/config";
import { pdfUrl } from "../utils/pdfUrl";
import Button from "../sharedUI/Button";

const StyledAudioCard = styled.div`
  border-radius: ${measurments.borderRadius}px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  background: ${colors.neutral[20]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem 1.5rem;
  svg {
    height: 20px;
    width: 20px;
  }
  .doc-card-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    p {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .doc-card-right {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    button {
      white-space: nowrap;
      font-size: 0.875rem;
    }
  }
`;

const AudioCard = ({ file, client }) => {
  if (!file) return null;

  return (
    <StyledAudioCard>
      <div className="doc-card-left">
        <p className="m0 b1">{file.name}</p>
      </div>
      <div className="doc-card-right">
        {!!client && (
          <>
            <a href={pdfUrl(file)} target="_blank" rel="noopener noreferrer">
              <Button btnType="neutral">Öppna</Button>
            </a>
            <a
              href={pdfUrl(file, true)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button>Ladda ner</Button>
            </a>
          </>
        )}
      </div>
    </StyledAudioCard>
  );
};

export default AudioCard;
