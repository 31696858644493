import React, { useState } from "react";
import styled from "styled-components";
import Input from "../sharedUI/Input";
import { colors } from "../utils/config";
import Button from "../sharedUI/Button";

const StyledApparatusInput = styled.div`
  p {
    margin: 0;
    margin-bottom: 0.25rem;
    color: ${colors.neutral[90]};
  }
  button {
    margin: 0;
    margin-bottom: 0.95rem;
    padding: 0.2rem 1rem !important;
  }
  .inputs {
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 2fr 1fr;
    gap: 1rem;
  }
  .existing {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    gap: 1rem;
    align-items: center;
    margin-bottom: 0.95rem;
    border-bottom: 1px solid ${colors.neutral[50]};
    p {
      margin: 0;
    }
  }
`;

const ApparatusInput = ({ value = [], onChange, apparatuses }) => {
  const [pendingName, setPendingName] = useState("");
  const [pendingQuantity, setPendingQuantity] = useState(1);

  const handleAdd = () => {
    if (!pendingName) return;
    const newApparatus = {
      name: pendingName,
      quantity: pendingQuantity,
    };
    onChange([...value, newApparatus]);
    setPendingName("");
    setPendingQuantity(1);
  };

  const handleRemove = (index) => {
    const newValue = value.filter((_, i) => i !== index);
    onChange(newValue);
  };

  return (
    <StyledApparatusInput>
      <label className="b2">Redskap</label>
      <div className="inputs">
        <datalist id="apparatuses">
          {apparatuses.map((app) => (
            <option key={`apparatus-datalistentry_${app}`}>{app}</option>
          ))}
        </datalist>
        <Input
          list="apparatuses"
          type="text"
          placeholder="Välj redskap"
          value={pendingName}
          onChange={(e) => setPendingName(e.target.value)}
        />
        <Input
          type="number"
          placeholder="Antal"
          value={pendingQuantity}
          onChange={(e) => setPendingQuantity(e.target.value)}
        />
        <Button className="add-button" onClick={handleAdd}>
          Lägg till
        </Button>
      </div>
      {value.map((apparatus, index) => (
        <div key={index} className="existing">
          <p>{apparatus.name}</p>
          <p>{apparatus.quantity}</p>
          <Button className="danger" onClick={() => handleRemove(index)}>
            Ta bort
          </Button>
        </div>
      ))}
    </StyledApparatusInput>
  );
};

export default ApparatusInput;
