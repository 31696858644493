import React, { useEffect, useMemo, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import styled from "styled-components";
import { Loader } from "../../sharedUI/FullScreenLoader";
import IconButton from "../../sharedUI/IconButton";
import { fetcher } from "../../utils/fetcher";
import GuideEntryForm from "../../components/forms/GuideEntryForm";
import GuideEntryCard from "../../components/cards/GuideEntryCard";
import DeleteConfirmation from "../../components/forms/DeleteConfirmation";
import Input from "../../sharedUI/Input";

const StyledGuide = styled.div`
  .guides-top {
    display: flex;
    justify-content: space-between;
    &-right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .search-input {
        margin: 0;
      }
    }
  }
  .entries {
    margin-top: 1rem;
  }
`;

const Guide = () => {
  const outlet = useOutletContext();
  const { globalState, notify, modal, closeModal } = outlet;

  //State
  const [guides, setGuides] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [apparatuses, setApparatuses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchString, setSearchString] = useState("");

  //Functions
  const getEntries = async () => {
    const { err, data } = await fetcher({
      path: "guide/admin",
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte hämta Övningsbank");
    } else {
      setGuides(data.entries);
      setApparatuses(data.apparatuses);
    }
  };

  const getCategories = async () => {
    const { err, data } = await fetcher({
      path: "guideCategories/admin",
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte hämta kategorier");
    } else {
      setCategories(data);
    }
  };

  const getTags = async () => {
    const { err, data } = await fetcher({
      path: "guideTags",
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte hämta taggar");
    } else {
      setTags(data);
    }
  };

  const getData = async () => {
    setLoading(true);
    const promises = [getEntries(), getCategories(), getTags()];
    await Promise.all(promises);
    setLoading(false);
  };

  const createEntry = async (formData, cb, errCb) => {
    const { err } = await fetcher({
      path: "guide",
      body: formData,
      method: "POST",
      auth: globalState.token,
    });
    if (err) {
      errCb();
      notify.error("Kunde inte skapa övning");
      return;
    }
    cb();
    closeModal();
    getData();
  };

  const editEntry = async (formData, cb, errCb) => {
    const { err } = await fetcher({
      path: `guide/${formData._id}`,
      body: formData,
      method: "POST",
      auth: globalState.token,
    });
    if (err) {
      errCb();
      notify.error("Kunde inte uppdatera övning");
      return;
    }
    cb();
    closeModal();
    getData();
  };

  const deleteEntry = async (entry, cb, errCb) => {
    const { err } = await fetcher({
      path: `guide/${entry._id}`,
      method: "DELETE",
      auth: globalState.token,
    });
    if (err) {
      errCb();
      notify.error("Kunde inte radera övning");
      return;
    }
    cb();
    closeModal();
    getData();
  };

  const deleteEntryInit = (entry) => {
    modal(
      <DeleteConfirmation
        item={entry}
        deleteKey={"title"}
        handleInvalidKey={() => notify.error("Fyll i rätt värde i fältet")}
        action={deleteEntry}
        name="övningen"
        instruction="övningens titel"
      />,
      "Radera övning"
    );
  };

  const editEntryInit = (entry) => {
    modal(
      <GuideEntryForm
        data={entry}
        apparatuses={apparatuses}
        handleSubmit={editEntry}
        outlet={outlet}
        categories={categories}
        tags={tags}
      />,
      "Redigera övning"
    );
  };

  const createEntryInit = () => {
    modal(
      <GuideEntryForm
        apparatuses={apparatuses}
        handleSubmit={createEntry}
        outlet={outlet}
        categories={categories}
        tags={tags}
      />,
      "Skapa ny övning"
    );
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const filteredEntries = useMemo(() => {
    if (!searchString) return guides;
    const s = searchString.toLocaleLowerCase();
    let matches = [];
    guides.forEach((entry) => {
      const search = entry.title.toLowerCase().search(s);
      if (search !== -1) matches.push(entry);
    });
    return matches;
  }, [guides, searchString]);

  return (
    <StyledGuide className="content ptop2">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex gap1 mbottom2">
            <Link to="/guide/categories">
              <IconButton iconRight="plus">Hantera kategorier</IconButton>
            </Link>
            <Link to="/guide/tags">
              <IconButton iconRight="plus">Hantera taggar</IconButton>
            </Link>
          </div>
          <div className="guides-top">
            <h2 className="m0 h2">Övningsbank</h2>
            <div className="guides-top-right">
              <Input
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                type="text"
                placeholder="Sök guide"
                className="search-input"
              />
              <IconButton iconRight="plus" onClick={createEntryInit}>
                Skapa ny
              </IconButton>
            </div>
          </div>
          <div className="entries">
            {filteredEntries.length ? (
              filteredEntries.map((entry) => (
                <GuideEntryCard
                  categories={categories}
                  tags={tags}
                  key={entry._id}
                  entry={entry}
                  deleteInit={deleteEntryInit}
                  editInit={editEntryInit}
                />
              ))
            ) : (
              <p>Inga övningar hittades</p>
            )}
          </div>
        </>
      )}
    </StyledGuide>
  );
};

export default Guide;
