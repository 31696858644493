import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import { fetcher } from "../../utils/fetcher";
import { Loader } from "../../sharedUI/FullScreenLoader";
import MusicForm from "../../components/forms/MusicForm";
import DeleteConfirmation from "../../components/forms/DeleteConfirmation";
import IconButton from "../../sharedUI/IconButton";
import MusicEntryCard from "../../components/cards/MusicEntryCard.js";

const StyledMusic = styled.div`
  .documents-top {
    display: flex;
    justify-content: space-between;
    &-right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .search-input {
        margin: 0;
      }
    }
  }
  .entries {
    margin-top: 1rem;
  }
`;

const Music = () => {
  const outlet = useOutletContext();
  const { globalState, notify, modal, closeModal } = outlet;

  const [musicFiles, setMusicFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const getEntries = async () => {
    setLoading(true);
    const { err, data } = await fetcher({
      path: "music/admin",
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte hämta musikfiler");
    } else {
      setMusicFiles(data);
    }
    setLoading(false);
  };

  const createEntry = async (formData, cb, errCb) => {
    const { err } = await fetcher({
      path: "music",
      body: formData,
      method: "POST",
      auth: globalState.token,
    });
    if (err) {
      errCb();
      notify.error("Kunde inte spara");
      return;
    }
    cb();
    closeModal();
    getEntries();
  };

  const editEntry = async (formData, cb, errCb) => {
    const { err } = await fetcher({
      path: `music/${formData._id}`,
      body: formData,
      method: "POST",
      auth: globalState.token,
    });
    if (err) {
      errCb();
      notify.error("Kunde inte uppdatera");
      return;
    }
    cb();
    closeModal();
    getEntries();
  };

  const deleteEntry = async (entry, cb, errCb) => {
    const { err } = await fetcher({
      path: `music/${entry._id}`,
      method: "DELETE",
      auth: globalState.token,
    });
    if (err) {
      errCb();
      notify.error("Kunde inte radera");
      return;
    }
    cb();
    closeModal();
    getEntries();
  };

  const deleteEntryInit = (entry) => {
    modal(
      <DeleteConfirmation
        item={entry}
        deleteKey={"title"}
        handleInvalidKey={() => notify.error("Fyll i rätt värde i fältet")}
        action={deleteEntry}
        name="filen"
        instruction="filens titel"
      />,
      "Radera musikfil"
    );
  };

  const editEntryInit = (entry) => {
    modal(
      <MusicForm data={entry} handleSubmit={editEntry} outlet={outlet} />,
      "Redigera musikfil"
    );
  };

  const createEntryInit = () => {
    modal(
      <MusicForm handleSubmit={createEntry} outlet={outlet} />,
      "Skapa musikfil"
    );
  };

  useEffect(() => {
    getEntries();
    // eslint-disable-next-line
  }, []);

  return (
    <StyledMusic className="content ptop2">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="documents-top">
            <h2 className="m0 h2">Musik</h2>
            <div className="documents-top-right">
              <IconButton iconRight="plus" onClick={createEntryInit}>
                Skapa nytt
              </IconButton>
            </div>
          </div>
          <div className="entries">
            {musicFiles.length ? (
              musicFiles.map((entry) => (
                <MusicEntryCard
                  key={entry._id}
                  entry={entry}
                  deleteInit={deleteEntryInit}
                  editInit={editEntryInit}
                />
              ))
            ) : (
              <p>Inga musikfiler hittades</p>
            )}
          </div>
        </>
      )}
    </StyledMusic>
  );
};

export default Music;
